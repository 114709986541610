import PropTypes from "prop-types"
import React from "react"

import "./flex.css"

const Flex = ({ children }) => (
  <div className="flex">
      {children}
  </div>
)

Flex.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Flex
