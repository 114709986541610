import React from "react"
import Fade from 'react-reveal/Fade';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import "./hero.css"

const Hero = () => (
  <div className="hero-container">
    <Fade left distance="5rem" delay={300}>
      <div className="hero--image"></div>
    </Fade>
    <div className="hero--right">
      <Fade top cascade distance="1.5rem" delay={300}>

        <div className="hero--right-content">
          <h1 className="gold">UX- ja UI-design</h1>
          <strong className="gold">Hyvin suunniteltu on yli puoliksi tehty</strong>
          <p>Laadukas verkkopalveluiden ja applikaatioiden suunnittelu ei ole pelkästään sitä, miltä palvelusi näyttää vaan myös sitä, miltä se tuntuu. Loppukäyttäjän tunteminen, ymmärtäminen ja tämän mielenliikkeiden ennustaminen on ensiarvoisen tärkeää voittavan lopputuloksen aikaansaamiseksi. Järjestelmällisen suunnittelun avulla syntyy sekä näyttäviä ja helppokäyttöisiä että aidosti arvoa tuottavia palveluita.</p>
          <p><strong>Esimerkkejä suunnittelun monista hyödyistä</strong></p>
          <ul>
            <li>Käyttäjät haluavat käyttää palvelua tai sovellusta</li>
            <li>Palvelusi tai sovelluksesi kestää aikaa paremmin</li>
            <li>Palvelua tai sovellusta voidaan testata ennen varsinaista tuotantoa</li>
            <li>Voit kilpailuttaa tuotannon suunnitelman pohjalta</li>
          </ul>

          <AnchorLink role="button" tabindex="0" aria-label="Siirry sisältöön" offset='100' href='#palvelukuvaus'>
            <svg width="32" height="32" version="1.1" viewBox="0 0 32 32"><g transform="scale(1.33333)"><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M16.5,18.497l-5,5l-5,-5"></path><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.5,23.5v-23"></path></g></svg>
          </AnchorLink>
        </div>
        
      </Fade>
    </div>
  </div>
)


export default Hero 
 