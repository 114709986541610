import React from "react"
import { CookiesProvider } from 'react-cookie';


import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import Services from "../components/services/services"



const IndexPage = () => (
  <CookiesProvider>
    <Layout>
      <SEO title="Käyttöliittymä- ja käyttökokemussuunnittelu sekä prototyypit" />
      <Hero />
      <Services />
    </Layout>
  </CookiesProvider>
)

export default IndexPage
