import React from "react"
import Fade from "react-reveal/Fade"

import "./footer.css"

const Footer = () => (
  <footer name="Sovi tapaamienn ja ota yhteyttä">
    <div className="border-top"></div>
    <div className="border-bottom"></div>
    <div className="border-left"></div>
    <div className="border-right"></div>

    <Fade top cascade distance="2rem">
      <div className="footer">
        <div className="footer__container">
          <div className="footer--left">
            <h4>Sovi tapaaminen</h4>
            <p>
              Selvitetään yhdessä voimmeko edistää liiketoimintaanne muotoilun
              keinoin. Jos palvelunne on vailla näyttävää ulkoasua,
              kokonaisvaltaista käyttökokemussuunnitelmaa tai et ole varma
              ideasi toimivuudesta, silloin Meiko on sopiva kumppani teille.
            </p>
            <p>
              Sovi tapaaminen, jossa käymme teidän nykytilanteen läpi ja
              kerromme mielellämme lisää suunnittelupalveluistamme.
            </p>
          </div>
          <div className="footer--right">
            <h4>Ota yhteyttä</h4>
            <p>
              Sampo Virmasalo // Marketing Director <br />{" "}
              <a
                className="contact-link"
                aria-label="Soita yhteyshenkilölle"
                href="tel:+358453492781"
              >
                +358 45 349 2781
              </a>{" "}
              <a
                className="contact-link"
                aria-label="Lähetä sähköpostia yhteyshenkilölle"
                href="mailto:sampo.virmasalo@meiko.fi"
              >
                sampo.virmasalo@meiko.fi
              </a>
            </p>

            <form action="https://formspree.io/xzwjynem" method="POST">
              <input
                aria-hidden="true"
                type="hidden"
                name="_next"
                aria-label=""
                value="https://parempaa-suunnittelua.meiko.fi/lomake-lahetetty"
              />
              <div className="gotcha">
                <input type="text" name="_gotcha" />
              </div>
              <input
                type="text"
                name="nimi"
                aria-label="Nimesi"
                placeholder="Nimesi"
              />
              <input
                type="text"
                name="yhteystieto"
                aria-label="Sähköposti tai puhelinnumero"
                placeholder="Sähköposti tai puhelin"
                required
              />
              <input aria-label="Lähetä lomake" type="submit" value="Lähetä" />
            </form>
          </div>
        </div>
      </div>
    </Fade>
  </footer>
)

export default Footer
